import React from "react";
import '../Styling/Components.css';
import '../Styling/Home.css';
import Footer from './Footer';
import ProfilePic from '../Styling/Assets/ProfileImg.jpg';

export default function Home() {
    return (
        <div className="profile-container">
            <div className="profile-content-container">
                <div className="profile-image-container">
                    <img
                        src={ProfilePic}
                        className="d-inline-block profile-pic"
                        alt="ProfilePicture"
                    />
                </div>
                <div className="profile-description">
                    <h1>BRYAN NICHOLAS CARTER</h1>
                    <h3>actor • producer • podcast host</h3>
                    <p>Bryan Carter, he/him is a Chicago based actor, producer and podcast host.</p>
                    
                    <p>In October 2024 Bryan wrapped filming for the upcoming web series, <i>Tori & Carmen</i> (Willow Corners Productions) and feature film, <i>Raffle</i> (Two Scoops Productions) and is now preparing to begin rehearsals for <i>The Long Christmas Dinner</i> (<a  href="https://www.tutatheatre.org/" target="_blank" rel="noreferrer" style={{ color: 'black' }}>TUTA Theatre Chicago</a>) and <i>Kid Prince & Pablo</i> (<a  href="https://lifelinetheatre.com/performances/2024-2025/kid-prince-and-pablo/" target="_blank" rel="noreferrer" style={{ color: 'black' }}>Lifeline Theatre</a>).</p>
                    
                    <p>Recent credits include, <i>The Left Hand of Darkness</i> (Chicago International Puppet Theatre); <i>Dontrell</i>, <i>Who Kissed the Sea</i> (Pegasus Theatre Chicago); <i>The Salon</i> (Black Ensemble Theatre); <i>Champion</i> (Lyric Opera of Chicago); <i>Middle Passage</i> (Lifeline Theatre); as well as appearing in season 2 of the web series, <i>The Life and Times of a Unicorn</i> produced by Adam Lorraine Productions. <a  href="/experience" style={{ color: 'black' }}>See Full Credits</a></p>
                    
                    <p>In 2020 Carter received prominent recognition by the Kennedy Center American College Theatre Festival, being awarded the national honor for Best Actor in an Outstanding Role as Nat Turner in <i>Nat Turner in Jerusalem</i> (University of Wisconsin Oshkosh) directed by Merlaine Angwall.</p>
                    
                    <p>From 2020 to 2022, Bryan worked closely with Best-Selling Author and Personal Brand Strategist, Jessica Zweig, producing several live and virtual events as well as producing and guest hosting her podcast, <i>The SimplyBe. Podcast</i>, now the <i>Spiritual Hustler Podcast</i>.</p>
                    
                    <p>Carter is an alumnus of The School at Steppenwolf and holds his B.A. in Political Science.</p>             
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

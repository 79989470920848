import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import '../Styling/Components.css';
import '../Styling/Contact.css';
import Footer from './Footer'
import ProfilePic from '../Styling/Assets/Contact-Img.jpg';

export default function ContactForm() {
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    
    const formData = {
      name: e.target.formName.value,
      email: e.target.formBasicEmail.value,
      subject: e.target.formSubjectLine.value,
      message: e.target.formMessageLine.value
    };

    const nameRegex = /^[a-zA-Z\s\-']{2,50}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const subjectRegex = /^.{5,100}$/;
    const messageRegex = /^.{10,}$/;

    let isValid = true;

    if (!nameRegex.test(formData.name)) {
      setNameError('Please enter a valid name (2-50 characters, letters, spaces, and hyphens only).');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!emailRegex.test(formData.email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!subjectRegex.test(formData.subject)) {
      setSubjectError('Please enter a subject (5-100 characters).');
      isValid = false;
    } else {
      setSubjectError('');
    }

    if (!messageRegex.test(formData.message)) {
      setMessageError('Please enter a message (minimum 10 characters).');
      isValid = false;
    } else {
      setMessageError('');
    }

    if (!isValid) {
      return;
    }

    setLoading(true)

    try {
      await axios.post(process.env.REACT_APP_BACKEND_URL + 'send', formData);
      setLoading(false);
      setShowModal(true);
    } catch (error) {
      setLoading(false);
      setShowErrorModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowErrorModal(false);
    setNameError('');
    setEmailError('');
    setSubjectError('');
    setMessageError('');
    const form = document.getElementById('contactForm');
    if (form) {
      form.reset();
    }
  };

  return (
    <div className= "contact-conatiner" >
      <div className='img-form-conatiner'>
        <div className="contact-img-container">
          <img
            src={ProfilePic}
            className="contact-img"
            alt="ProfilePicture"
          />
        </div>
        <div className='contact-form'>
          <div>
            <h1 style={{ margin:'10px' }}>contact</h1>
            <div className='agent-info'>
              <h6 style={{ fontSize: '0.875rem', margin:'10px' }}>Chicago Theatrical & Commercial Inquiries: Lauren Smith, Talent X Alexander, <a href="mailto: lsmith@talentxalexander.com" style={{ color: 'black' }}>lsmith@talentxalexander.com</a></h6>
              <h6 style={{ fontSize: '0.875rem', margin:'10px' }}>L.A., New York, ATL Theatrical Inquiries: Rebecca Allfrey, Firestarter Entertainment, <a href="mailto:admin@fsetalent.com" style={{ color: 'black' }}>admin@fsetalent.com</a></h6>
              <h6 style={{ fontSize: '0.875rem', margin:'10px' }}>All other inquiries can be sent to: <a href="mailto:b.nicholascarter@gmail.com" style={{ color: 'black' }}>b.nicholascarter@gmail.com</a></h6>    
            </div>
          </div>
          <Form id="contactForm" className='form-div' onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control type="name" placeholder="Name" />
              {nameError && <Form.Text className="text-danger">{nameError}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="email" placeholder="Email" />
              {emailError && <Form.Text className="text-danger">{emailError}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSubjectLine">
              <Form.Control type="subject" placeholder="Subject" />
              {subjectError && <Form.Text className="text-danger">{subjectError}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-5" controlId="formMessageLine">
              <Form.Control as="textarea" rows={5} placeholder="Message" />
              {messageError && <Form.Text className="text-danger">{messageError}</Form.Text>}
            </Form.Group>
            <Button variant="dark" type="submit" className='submit-button'>
              Submit
            </Button>
          </Form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <Modal show={loading} centered>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="dark" />
            <span style={{ marginLeft: '10px' }}>Sending message...</span>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Email sent successfully!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showErrorModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Something went wrong, please try again later.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
